
export function arrGroupBy (array, fun) {
  const groups = {};
  array.forEach(function (o) {
    const group = fun(o);
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  let newArr = [];
  Object.keys(groups).map(function (group, index) {
    newArr.push({
      key: index,
      title: group,
      children: groups[group],
    });
  });
  return newArr;
}

/**
 * 将数据格式化为树型结构数据
 * @param {*} list 
 */
export function formatTreeData (list = []) {
  const res = [];
  const map = list.reduce((res, v) => (res[v.id] = v, res), {});
  for (const item of list) {
    if (item.pid === '') {
      res.push(item);
      continue;
    }
    if (item.pid in map) {
      const parent = map[item.pid];
      parent.children = parent.children || [];
      parent.children.push(item);
    }
  }
  return res;
}

/**
 * 根据时间生成时间戳
 */
export function getUUId () {
  let random = function () { // 生成10-12位不等的字符串
    return Number(Math.random().toString().substr(2)).toString(36); // 转换成十六进制
  }
  return random();
}


/***
 * @description 格式化日期
 * @param time
 */
/**
 * Created by lihong
 */
export function formatDate (time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}


export function rowSpan (dataArr, key) {
  let arr = dataArr
    .reduce((result, item) => {
      if (result.indexOf(item[key]) < 0) {
        result.push(item[key]);
      }
      return result;
    }, [])
    .reduce((result, keys) => {
      const children = dataArr.filter((item) => item[key] === keys);
      result = result.concat(
        children.map((item, index) => ({
          ...item,
          [`${key}RowSpan`]: index === 0 ? children.length : 0,
        }))
      );
      return result;
    }, []);

  return arr;
}


/**
 * 计算两个日期之间的天数
 * @param dateString1  开始日期 yyyy-MM-dd
 * @param dateString2  结束日期 yyyy-MM-dd
 * @returns {number} 如果日期相同 返回一天 开始日期大于结束日期，返回0
 */
 export function getDaysBetween(dateString1,dateString2){
  var  startDate = Date.parse(dateString1);
  var  endDate = Date.parse(dateString2);
  if (startDate>endDate){
      return 0;
  }
  if (startDate==endDate){
      return 1;
  }
  var days=(endDate - startDate)/(1*24*60*60*1000);
  return  days;
}


/**
 * 数字不足两位补零
 * @param {*} n 
 */
export function formatNumber(n) {
  n = n.toString()
  return n[1] ? n : `0${n}`
}

/**
 * 把金额转化成英美格式
 * @param {*} n 
 */
export function formatEnMoney(val, isCent, isNegative) {
  val = typeof val == "undefined" ? 0 : val
  val = val.toString().replace(/[^\\.\d-]*/g, "")
  if(/\.[\d]{2}/.test(val)){
    val = parseFloat(val).toFixed(2)
  }

  let arr = val.split(".")
  let num_int = arr[0]
  let num_flt = arr[1]
  num_int = num_int.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  if(isCent){
    num_flt = num_flt || "00"
    num_flt = (num_flt&&num_flt[1]) ? num_flt : `${num_flt}0`
  }

  if(Number(val) > 0 && isNegative){
    return "-" + (num_flt ? (num_int+"."+num_flt) : num_int)
  }
  return num_flt ? (num_int+"."+num_flt) : num_int
}

const getDate = function (num, date = new Date()) {
  date = date.valueOf()
  date = date + num * 24 * 60 * 60 * 1000;
  date = new Date(date);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const dateday = date.getDate();
  let currentTime = year + '-' + formatNumber(month) + "-" + formatNumber(dateday);
  return currentTime;
}

const getWeek = function (num, date = new Date()) {
  date = date.valueOf()
  date = date + num * 24 * 60 * 60 * 1000;
  date = new Date(date);
  const day = new Array("周日", "周一", "周二", "周三", "周四", "周五", "周六")[date.getDay()];
  return day;
}


/**
 * 获取从今天起后n天的日期列表
 * @length  列表长度 
 * @date  如果传第二个日期参数，则从这个日期开始往后算长度
 */
 export function getDateList(length, date) {
  let arr = []
  for (let i = 0; i < length; i++) {
    arr.push({
      id: i,
      name: getDate(i, date) + " " + getWeek(i, date),
      value: getDate(i, date)
    })
  }
  return arr
}

/**
 * 获取时间段 每半小时一个段
 * @length  列表长度 
 * @date  如果传第二个日期参数，则从这个日期开始往后算长度
 */
// export function getTimeList(start, end, base) {
//   let arr = [];
//   let s = 0;
//   while (s < 24 * 3600 * 1000) {
//     if ((base + s >= start) && (base + s + 1800*1000 <= end)) {
//       let fs = formatNumber(new Date(base + s).getHours()) + ":" + formatNumber(new Date(base + s).getMinutes())
//       let fe = formatNumber(new Date(base + s + 1800 * 1000).getHours()) + ":" + formatNumber(new Date(base + s + 1800 * 1000).getMinutes())
//       arr.push({
//         name: fs + "-" + fe,
//         value: s,
//         start: fs,
//         end: fe
//       });
//     }
//     s += 1800 * 1000;
//   }
//   return arr;
// }

export function getTimeList(base, cfg) {
  if(!cfg.start || !cfg.end) return []

  const DUR   = (cfg && cfg.duration || 30) * 60 * 1000 // 跨度时长 默认30分钟
  const DELTA = (cfg && cfg.delta    || 30) * 60 * 1000 // 时间间隔 默认30分钟

  let arr = [];
  let optStart = base
  while (optStart < cfg.end) {
    const optEnd = optStart + DUR
    // 将0~24点按照DELTA做分割，在start~end之间的就可以
    if (optStart >= cfg.start && optEnd <= cfg.end) {
      let fs = formatNumber(new Date(optStart).getHours()) + ":" + formatNumber(new Date(optStart).getMinutes())
      let fe = formatNumber(new Date(optEnd).getHours()) + ":" + formatNumber(new Date(optEnd).getMinutes())
      arr.push({
        value: optStart - base,
        start: fs,
        end:   fe,
        name:  fs + "-" + fe,
      });
    }
    optStart = optStart + DELTA
  }

  return arr;
}

export function getTimeList2(base, cfg) {
  if(!cfg.start || !cfg.end) return []

  const DELTA = (cfg && cfg.delta    || 30) * 60 * 1000 // 时间间隔 默认30分钟

  let arr = [];
  let optStart = base
  while (optStart < cfg.end) {
    let timeItem = {}
    let endList = []
    let optEnd = optStart + 30*60*1000
    if (optStart >= cfg.start && optEnd <= cfg.end) {
      let fs = formatNumber(new Date(optStart).getHours()) + ":" + formatNumber(new Date(optStart).getMinutes())
      timeItem = {
        value: optStart - base,
        start: fs,
        // end:   fe,
        // name:  fs + "-" + fe,
        name:  fs,
        // children: endList,
      }
      // 次级选项：半小时（默认）
      let fe = formatNumber(new Date(optEnd).getHours()) + ":" + formatNumber(new Date(optEnd).getMinutes())
      endList.push({
        value: optStart - base,
        start: fs,
        end:   fe,
        name:  fs + "-" + fe,
      })
      // 次级选项：1小时
      optEnd = optStart + 60*60*1000
      if (optEnd <= cfg.end) {
        // let fs = formatNumber(new Date(optStart).getHours()) + ":" + formatNumber(new Date(optStart).getMinutes())
        let fe = formatNumber(new Date(optEnd).getHours()) + ":" + formatNumber(new Date(optEnd).getMinutes())
        endList.push({
          value: optStart - base,
          start: fs,
          end:   fe,
          name:  fs + "-" + fe,
        })
      }
      // 次级选项：精准
      endList.push({
        value: optStart - base,
        start: fs,
        end:   fs,
        // name:  fs + "-" + fs,
        name:  fs,
      })
      
      timeItem.children = endList
      arr.push(timeItem)
    }

    optStart = optStart + DELTA
  }

  return arr;
}

/**
 * 验证是否是纯数字
 * @params 传入的参数
 */
export function verifyIsNumber(params) {
  console.log(params);
  var regular = /^[0-9]+.?[0-9]*$/; //能输入正数包括小数
  console.log(regular);
}

export function filterList(arr, attr){
  const newArr = [], attrArr = []
  for(let i =0;i<arr.length;i++){
    if(!attrArr.includes(arr[i][attr])){
      newArr.push(arr[i])
      attrArr.push(arr[i][attr])
    }
  }
  return newArr
}