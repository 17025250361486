import ajax from "@/utils/ajax.js";

/////////////////////门店////////////////////
/**
 * 获取门店分类
 * @param {*} params
 * @returns
 */
export function getTypeList(params) {
  return ajax.post("/XcxShopPageGoodsType/getTypeList", params);
}

/**
 * 获取门店子分类列表
 * @param {*} params
 * @returns
 */
export function getSubTypeList(params) {
  return ajax.post("/XcxShopPageGoodsType/getSonTypeList", params);
}

/**
 * 编辑门店分类
 * @param {*} params
 * @returns
 */
export function editType(params) {
  return ajax.post("/XcxShopPageGoodsType/editType", params);
}

/**
 * 编辑门店子分类
 * @param {*} params
 * @returns
 */
export function editSubType(params) {
  return ajax.post("/XcxShopPageGoodsType/editSonType", params);
}

/**
 * 根据类型ID获取分类列表
 * @param {*} params
 * @returns
 */
export function getGoodsListByTypeId(params) {
  return ajax.post("/XcxShopPageGoodsTypeGoods/getList", params);
}

/**
 * 根据类型ID获取分类列表
 * @param {*} params
 * @returns
 */
export function editTypeGoods(params) {
  return ajax.post("/XcxShopPageGoodsTypeGoods/editTypeGoods", params);
}

/**
 * 获取小程序在售 上次版本对比
 * @returns 
 */
export function getXcxDiffGoods(params){
  return ajax.post('/XcxShopPageGoodsBak/getDiffData', params)
}

/**
 * 获取小程序在售 上次版本对比
 * @returns 
 */
export function offShelvesXcxDiffGoods(params){
  return ajax.post('/XcxShopPageGoodsBak/allOutletsOffShelvesNotSaleGoods', params)

}


/**
 * 网点经营-->配置详情
 * @param {*} params
 * @returns
 */
export function configDetail(params) {
  return ajax.post("/OutletsSettings/detail", params);
}

/**
 * 网点经营-->修改配置
 * @param {*} params
 * @returns
 */
export function modifyConfig(params) {
  return ajax.post("/OutletsSettings/updateOutlets", params);
}

/**
 * 网点经营-->修改今日配置
 * @param {*} params
 * @returns
 */
export function modifyTodayConfig(params) {
  return ajax.post("/OutletsSettings/updateToday", params);
}


/**
 * 门店经营-->售卖商品
 * @param {*} params
 * @returns
 */
export function getSaleGoodsList(params) {
  return ajax.post("/OutletsGoodsAll/getList", params);
}

/**
 * 修改门店的蛋糕剩余总库存
 * @param {*} params
 * @returns
 */
export function editGoodsLeftStock(params) {
  return ajax.post("/OutletsGoodsAll/editGoodsTotalStock", params);
}

/**
 * 门店经营-->售卖商品(区分蛋糕/非蛋糕)
 * @param {*} params
 * @returns
 */
// export function getSaleGoodsList(params) {
//   if (params.isCake) {
//     return ajax.post("/OutletsGoodsCake/getList", params);
//   } else {
//     return ajax.post("/OutletsGoodsOther/getList", params);
//   }
// }

/**
 * 门店经营-->编辑商品数据
 * @param {*} params
 * @returns
 */
export function editGoodsData(params, isCake) {
  if (isCake) {
    return ajax.post("/OutletsGoodsCake/editGoodsData", params);
  } else {
    return ajax.post("/OutletsGoodsOther/editGoodsData", params);
  }
}

/**
 * 门店经营-->商品列表-添加使用
 * @param {*} params
 * @returns
 */
export function addGoodsList(params, isCake) {
  if(isCake){
  return ajax.post("/OutletsGoodsCake/getListAddUse", params);
  }else{
  return ajax.post("/OutletsGoodsOther/getListAddUse", params);

  }
}

/**
 * 门店经营-->添加商品
 * @param {*} params
 * @returns
 */
export function addGoods(params) {
  return ajax.post("/OutletsGoodsOther/addGoods", params);
}

/**
 * 门店经营-->同步网点数据
 * @param {*} params
 * @returns
 */
export function syncOutletsData(params) {
  if (params.isCake) {
    return ajax.post("/OutletsGoodsCake/copyOutletsData", params);
  } else {
    return ajax.post("/OutletsGoodsOther/copyOutletsData", params);
  }
}


/**
 * 搜索门店接口
 * @param {*} params 
 * @returns 
 */
export function getSearchShopList(params) {
  return ajax.post("/OutletsGoodsCommonApi/searchShop", params);
}


/**
 * 根据商品ID获取商品的SKUS数据
 * @param {*} params 
 * @returns 
 */
 export function getSkuListByGoodsId(params,isCake) {
   if(isCake){
    return ajax.post("/OutletsGoodsCake/getSkuListAddUse", params);
   }else{
    return ajax.post("/OutletsGoodsOther/getSkuListAddUse", params);
   }
}

/**
 * 获取门店广告位
 * @returns
 */
export function getAdvertList() {
  return ajax.post("/OutletsAd/getListPosition1");
}

/**
 * 修改门店广告位
 * @returns
 */
export function editAdverts(params) {
  return ajax.post("/OutletsAd/editDataPosition1", params);
}

/**
 * 获取订单列表
 * @returns
 */
export function getShopOrderList(params) {
  return ajax.post("/OutletsSuborder/getList", params);
}

/**
 * 获取订单分类汇总数
 * @returns
 */
export function getShopOrderCountList1(params) {
  return ajax.post("/OutletsSuborder/getCountList1", params);
}

/**
 * 获取订单详情
 * @returns
 */
export function getShopOrderDesc(params) {
  return ajax.post("/OutletsSuborder/detail", params);
}

/**
 * 修改门店订单状态
 * @returns 
 */
export function updateOrderStatus(params){
  return ajax.post('/OutletsSuborder/updateStatus', params)
}

/**
 * 门店：取消订单（未拆单）
 * @returns 
 */
export function cancelOrder(params){
  return ajax.post('/OutletsSuborder/cancel', params)
}

/**
 * 获取门店下单列表
 * @returns 
 */
 export function getAllOrderList(params){
  return ajax.post('/OutletsOrder/getList', params)
}

/**
 * 获取门店下单详情
 * @returns 
 */
 export function getAllOrderDetail(params){
  return ajax.post('/OutletsOrder/detail', params)
}

/**
 * 取消订单-蛋糕订单（网点自己下单的）
 * @param {*} params
 * @returns
 */
export function cancelCakeOrder(params) {
  return ajax.post("/OutletsOrder/cancel", params);
}


/**
 * 获取商品价格图片信息
 * @param {*} params 
 * @returns 
 */
 export function getGoodsPriceList(params) {
  return ajax.post('/XcxShopPageGoodsTypeGoods/getListAddUse',params)
}


/**
 * 获取门店小程序线下核销记录
 * @returns 
 */
export function getOfflineOrderList(params){
  return ajax.post('/OfflineCashRegisterOrder/verificationRecord', params)
}
